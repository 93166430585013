import React, { useState, useEffect } from "react";
import ReactBody from "react-body";
import { useSelector } from "react-redux";
import { firebase } from "../../firebase";
import Content from "../content/Content";
import ContentMeta from "../content/ContentMeta";
import { Helmet } from "react-helmet-async";
import { db } from "../../firebase";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState(null);
  const [emailSending, setEmailSending] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [emailContact, setEmailContact] = useState("");

  useEffect(() => {
    db.getSettings().then((res) => {
      setEmailContact(res?.email?.emailContact ? res.email.emailContact : "");
    });
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    setEmailSending(true);

    firebase
      .sendContactEmail({
        to: emailContact,
        name,
        email,
        company,
        phone,
        message,
      })
      .then(() => {
        setName("");
        setEmail("");
        setMessage("");
        setCompany("");
        setPhone("");
        setError(null);
        setEmailSending(false);
        setEmailSent(true);
        setTimeout(() => {
          setEmailSent(false);
        }, 4000);
      })
      .catch((error) => {
        setError(error);
        setEmailSending(false);
      });
  };

  const isInvalid = name === "" || email === "" || message === "";

  return (
    <form className="contact-form" onSubmit={onSubmit}>
      <input
        type="text"
        value={name}
        placeholder="* Name"
        onChange={(event) => setName(event.target.value)}
      />
      <input
        type="email"
        value={email}
        placeholder="* Email"
        onChange={(event) => setEmail(event.target.value)}
      />
      <input
        className="col-2"
        type="text"
        value={company}
        placeholder="Company"
        onChange={(event) => setCompany(event.target.value)}
      />
      <input
        className="col-2"
        type="tel"
        value={phone}
        placeholder="Phone"
        onChange={(event) => setPhone(event.target.value)}
      />
      <textarea
        value={message}
        placeholder="* How can we help you?"
        rows="4"
        onChange={(event) => setMessage(event.target.value)}
      />
      <button
        id="submit"
        className={`btn btn-quaternary ${emailSent ? "btn-success" : ""} ${
          emailSending ? "sending" : ""
        }`}
        type="submit"
        disabled={isInvalid}
      >
        {!emailSending && !emailSent ? (
          <span>send message</span>
        ) : !emailSending && emailSent ? (
          <span>
            <i className="icon icon-checkmark-circle" /> message sent!
          </span>
        ) : (
          <span className="btn-loading" />
        )}
      </button>
      {error && <p className="error">{error.message}</p>}
    </form>
  );
};

export { ContactForm };

const Contact = (props) => {
  const authUser = useSelector((state) => state.sessionState.authUser);
  const [pageContent, setPageContent] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    getContent();
  }, []);

  const getContent = () => {
    db.getPageContent("contact").then((content) => {
      setPageContent(content.val());
    });
  };

  return pageContent ? (
    <section id="contact">
      <Helmet>
        <title>
          {pageContent.meta && pageContent.meta.title
            ? pageContent.meta.title
            : "Alluvion Contact"}
        </title>
        <meta
          name="description"
          content={
            pageContent.meta && pageContent.meta.description
              ? pageContent.meta.description
              : "Alluvion Communications provides fast, smart, reliable networks in the Phoenix, AZ area. Browse all of our Fiber, Data, and Voice services and contact us."
          }
        />
      </Helmet>
      {authUser && <ContentMeta page="contact" getContent={getContent} />}
      <div className="content-intro">
        <div className="content-wrap">
          <div className="contact-text">
            <Content
              page="contact"
              pageContent={pageContent}
              item="header"
              type="content"
            />
          </div>
          <div className="contact-form-wrap">
            <h1>Contact Us</h1>
            <ContactForm />
          </div>
        </div>
      </div>
      <ReactBody className="contact" />
    </section>
  ) : (
    <div className="content-intro">
      <div className="content-loading page-loading" />
    </div>
  );
};

export default Contact;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { HashLink as Link } from "react-router-hash-link";
import Particles from "react-particles-js";
import * as routes from "constants/routes";
import TextyAnim from "rc-texty";
import ReactBody from "react-body";
import Content from "../content/Content";
import ContentMeta from "../content/ContentMeta";
import Testimonials from "./Testimonials";
import { Helmet } from "react-helmet-async";
import { db } from "../../firebase";

const Home = () => {
  const authUser = useSelector((state) => state.sessionState.authUser);
  const [pageContent, setPageContent] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    getContent();
  }, []);

  const getContent = () => {
    db.getPageContent("home").then((content) => {
      setPageContent(content.val());
    });
  };

  const findHash = () => {
    if (window.location.hash.indexOf("testimonials") !== -1) {
      document.getElementById("testimonials").scrollIntoView();
    }
  };

  const homeText1 = "Smart. Fast. Reliable.";
  const homeText2 = "Smart People.";
  const homeText3 = "Fast Responses.";
  const homeText4 = "Reliable Networks.";
  const homeText5 = "Smart. Fast. Reliable.";

  return pageContent ? (
    <section id="home" onLoad={findHash}>
      <Helmet>
        <title>
          {pageContent.meta && pageContent.meta.title
            ? pageContent.meta.title
            : "Alluvion Communications"}
        </title>
        <meta
          name="description"
          content={
            pageContent.meta && pageContent.meta.description
              ? pageContent.meta.description
              : "Alluvion Communications provides fast, smart, reliable networks in the Phoenix, AZ area. Browse all of our Fiber, Data, and Voice services and contact us."
          }
        />
      </Helmet>
      {authUser ? <ContentMeta page="home" /> : null}
      <div className="home-bg" />
      <div className="home-intro">
        {window.innerWidth > 768 ? (
          <div className="home-intro-particles">
            <Particles
              style={{
                width: "100%",
                height: "100%",
              }}
              params={{
                particles: {
                  number: {
                    value: 200,
                    density: {
                      enabled: true,
                      value_area: 500,
                    },
                  },
                  color: {
                    value: "#34afd7",
                  },
                  shape: {
                    type: "circle",
                    polygon: {
                      nb_sides: 5,
                    },
                  },
                  opacity: {
                    value: 0.925,
                    random: false,
                    anim: {
                      enable: false,
                      speed: 1,
                      opacity_min: 0.1,
                      sync: false,
                    },
                  },
                  size: {
                    value: 7,
                    random: true,
                    anim: {
                      enable: true,
                      speed: 5,
                      size_min: 0.1,
                      sync: false,
                    },
                  },
                  line_linked: {
                    color: "#34afd7",
                    enable: true,
                    distance: 100,
                    opacity: 0.5,
                    width: 1,
                  },
                  move: {
                    enable: true,
                    speed: 1,
                    direction: "none",
                    random: false,
                    straight: false,
                    bounce: false,
                    attract: {
                      enable: false,
                      rotateX: 600,
                      rotateY: 1200,
                    },
                  },
                },
                interactivity: {
                  detect_on: "canvas",
                  events: {
                    onhover: {
                      enable: true,
                      mode: "bubble",
                    },
                    onclick: {
                      enable: true,
                      mode: "push",
                    },
                    resize: true,
                  },
                  modes: {
                    grab: {
                      distance: 400,
                      line_linked: {
                        opacity: 1,
                      },
                    },
                    bubble: {
                      distance: 200,
                      size: 10,
                      duration: 2,
                      opacity: 1,
                      speed: 4,
                    },
                    repulse: {
                      distance: 100,
                      duration: 0.4,
                    },
                    push: {
                      particles_nb: 4,
                    },
                    remove: {
                      particles_nb: 2,
                    },
                  },
                },
                retina_detect: true,
              }}
            />
          </div>
        ) : null}
        <h1>
          {window.innerWidth > 768 ? (
            <div className="texty-wrap">
              <TextyAnim
                id="texty-1"
                duration={(e) => {
                  if (e.index === 2) {
                    return 500;
                  }
                  return 1000;
                }}
                onEnd={(e) => {
                  if (e.key.indexOf(homeText1.length - 1) !== -1) {
                    document.getElementById("texty-1").className += " done";
                  }
                }}
              >
                {homeText1}
              </TextyAnim>
              <TextyAnim
                id="texty-2"
                duration={(e) => {
                  if (e.index === 2) {
                    return 500;
                  }
                  return 1000;
                }}
                delay={2500}
                onEnd={(e) => {
                  if (e.key.indexOf(homeText2.length - 1) !== -1) {
                    document.getElementById("texty-2").className += " done";
                  }
                }}
              >
                {homeText2}
              </TextyAnim>
              <TextyAnim
                id="texty-3"
                duration={(e) => {
                  if (e.index === 2) {
                    return 500;
                  }
                  return 1000;
                }}
                delay={4500}
                onEnd={(e) => {
                  if (e.key.indexOf(homeText3.length - 1) !== -1) {
                    document.getElementById("texty-3").className += " done";
                  }
                }}
              >
                {homeText3}
              </TextyAnim>
              <TextyAnim
                id="texty-4"
                duration={(e) => {
                  if (e.index === 2) {
                    return 500;
                  }
                  return 1000;
                }}
                delay={6500}
                onEnd={(e) => {
                  if (e.key.indexOf(homeText4.length - 1) !== -1) {
                    document.getElementById("texty-4").className += " done";
                  }
                }}
              >
                {homeText4}
              </TextyAnim>
              <TextyAnim
                id="texty-5"
                duration={(e) => {
                  if (e.index === 2) {
                    return 500;
                  }
                  return 1000;
                }}
                delay={8750}
              >
                {homeText5}
              </TextyAnim>
            </div>
          ) : (
            "Smart. Fast. Reliable"
          )}
        </h1>

        <Content
          page="home"
          pageContent={pageContent}
          item="subHeader"
          type="content"
        />

        <Link className="btn btn-accent" to={routes.Fiber}>
          view our fiber solutions
        </Link>
      </div>
      <div className="home-services">
        <div className="home-services-content">
          <img
            src={require("../../images/icon-home-fiber-blue.svg").default}
            alt="Hosted PBX"
          />
          <Content
            pageContent={pageContent}
            page="home"
            item="services"
            module="slim"
            itemIndex="0"
            type="content"
          />
          <Link to={routes.Fiber}>
            <button className="btn btn-primary btn-hollow">
              fiber solutions
            </button>
          </Link>
        </div>

        <div className="home-services-content">
          <img
            src={require("../../images/icon-home-network-blue.svg").default}
            alt="Wholesale SIP"
          />
          <Content
            pageContent={pageContent}
            page="home"
            item="services"
            itemIndex="1"
            type="content"
          />
          <Link to={routes.Data}>
            <button className="btn btn-primary btn-hollow">
              data solutions
            </button>
          </Link>
        </div>
        <div className="home-services-content">
          <img
            src={require("../../images/icon-home-headphones-blue.svg").default}
            alt="T1 Connections"
          />
          <Content
            pageContent={pageContent}
            page="home"
            item="services"
            itemIndex="2"
            type="content"
          />
          <Link to={routes.Voice}>
            <button className="btn btn-primary btn-hollow">
              voice solutions
            </button>
          </Link>
        </div>
      </div>
      <div className="home-features">
        <div className="home-features-bg" />
        <div className="home-features-content">
          <Content
            page="home"
            pageContent={pageContent}
            item="features"
            type="content"
          />
          <Link to={routes.About}>
            <button className="btn btn-accent">more about alluvion</button>
          </Link>
        </div>
      </div>
      <div className="home-solutions">
        <div className="home-solutions-header">
          <Content
            page="home"
            pageContent={pageContent}
            item="solutions"
            itemIndex="header"
            type="content"
          />
        </div>
        <div className="home-solutions-grid">
          <div className="home-solutions-content solutions-education">
            <img
              className="home-solutions-img"
              src={require("../../images/icons/graduated-blue.svg").default}
              alt="home-education"
            />
            <div className="home-solutions-text">
              <Content
                page="home"
                pageContent={pageContent}
                item="solutions"
                itemIndex="0"
                type="content"
              />
            </div>
            <Link to={`${routes.About}#industry-education`}>
              <button className="btn btn-primary btn-hollow">
                education solutions
              </button>
            </Link>
          </div>
          <div className="home-solutions-content solutions-finance">
            <img
              className="home-solutions-img"
              src={require("../../images/icons/brokerage-blue.svg").default}
              alt="home-finance"
            />
            <div className="home-solutions-text">
              <Content
                page="home"
                pageContent={pageContent}
                item="solutions"
                itemIndex="1"
                type="content"
              />
            </div>
            <Link to={`${routes.About}#industry-financial`}>
              <button className="btn btn-primary btn-hollow">
                finance solutions
              </button>
            </Link>
          </div>
          <div className="home-solutions-content solutions-healthcare">
            <img
              className="home-solutions-img"
              src={require("../../images/icons/hospital-blue.svg").default}
              alt="home-healthcare"
            />
            <div className="home-solutions-text">
              <Content
                page="home"
                pageContent={pageContent}
                item="solutions"
                itemIndex="2"
                type="content"
              />
            </div>
            <Link to={`${routes.About}#industry-healthcare`}>
              <button className="btn btn-primary btn-hollow">
                healthcare solutions
              </button>
            </Link>
          </div>
          <div className="home-solutions-content solutions-hotels">
            <img
              className="home-solutions-img"
              src={require("../../images/icons/suite-blue.svg").default}
              alt="home-hospitality"
            />
            <div className="home-solutions-text">
              <Content
                page="home"
                pageContent={pageContent}
                item="solutions"
                itemIndex="3"
                type="content"
              />
            </div>
            <Link to={`${routes.About}#industry-hospitality`}>
              <button className="btn btn-primary btn-hollow">
                hospitality solutions
              </button>
            </Link>
          </div>
        </div>
        <Testimonials
          authUser={authUser}
          page="home"
          pageContent={pageContent}
        />
      </div>
      <div className="home-map">
        <div id="service-map">
          <Content
            pageContent={pageContent}
            page="home"
            item="map"
            type="title"
          />
          <Content
            page="home"
            pageContent={pageContent}
            item="map"
            type="content"
          />
          <Link to={routes.Contact}>
            <button className="btn btn-accent">get started today</button>
          </Link>
        </div>
      </div>
      <ReactBody className="home" />
    </section>
  ) : (
    <div className="content-intro">
      <div className="content-loading page-loading" />
    </div>
  );
};

export default Home;

import React, { useState, useEffect } from "react";

const ContentDisplay = ({ pageContent, item, itemIndex, type }) => {
  const [content, setContent] = useState([]);

  useEffect(() => {
    setContent(
      pageContent && pageContent[item] && pageContent[item][itemIndex]
        ? pageContent[item][itemIndex][type]
        : pageContent[item][type]
        ? pageContent[item][type]
        : []
    );
  }, [pageContent, item, itemIndex, type]);

  return content && content.length > 0 ? (
    <div className="content-text">
      <span dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  ) : null;
};

export default ContentDisplay;

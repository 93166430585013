import React from "react";
import { useSelector } from "react-redux";
import ContentEditor from "../content/ContentEditor";
import ContentDisplay from "../content/ContentDisplay";

const Content = (props) => {
  const authUser = useSelector((state) => state.sessionState.authUser);
  const { page, pageContent, item, itemIndex, type, solo, getContent } = props;
  return (
    <>
      {authUser ? (
        <ContentEditor
          page={page}
          pageContent={pageContent}
          item={item}
          itemIndex={itemIndex}
          type={type}
          solo={solo}
          getContent={getContent}
        />
      ) : (
        <ContentDisplay
          page={page}
          pageContent={pageContent}
          item={item}
          itemIndex={itemIndex}
          type={type}
        />
      )}
    </>
  );
};

export default Content;

import { auth, db, firebase } from "../firebase";
import * as routes from "constants/routes";

export const inviteAdmin = (event, email, newUserId, callback) => {
  event.preventDefault();
  return db.getUsers().then((users) => {
    const currentUsers = users.val();
    let duplicateUser;
    Object.keys(currentUsers).forEach((key) => {
      if (currentUsers[key].email === email) {
        duplicateUser = email;
      }
    });
    if (duplicateUser) {
      return { error: true, message: "The user already exists." };
    } else {
      return db.getInvites().then((res) => {
        const currentInvites = res.val();
        let duplicateEmailKey;
        if (currentInvites) {
          Object.keys(currentInvites).forEach((key) => {
            if (currentInvites[key].email === email) {
              duplicateEmailKey = key;
            }
          });
        }
        if (duplicateEmailKey) {
          return db
            .removeNewUserFromItemInviteTable(duplicateEmailKey)
            .then(() => db.inviteNewUser(newUserId, email, callback));
        } else {
          return db.inviteNewUser(newUserId, email, callback);
        }
      });
    }
  });
};

export const onLogin = (event, state, handleSetState) => {
  event.preventDefault();
  const { email, password } = state;
  auth.doSignInWithEmailAndPassword(email, password).catch((error) => {
    handleSetState({ error, userLoginState: false });
  });
};

export const onLogOut = (history) => {
  auth.doSignOut().then(() => {
    history.push(routes.Admin);
  });
};

export const onSignUp = (state, ID, handleSetState, callback) => {
  const { email, password } = state;

  auth
    .doCreateUserWithEmailAndPassword(email, password)
    .then(() => {
      const user = firebase.auth.currentUser;
      db.createUser(user.uid, email, "ADMIN")
        .then(() => {
          db.removeNewUserFromItemInviteTable(ID);
          if (callback) {
            callback();
          }
        })
        .catch((error) => {
          handleSetState({ error });
        });
    })
    .catch((error) => {
      handleSetState({ error: { message: "You are not authorized" } });
    });
};

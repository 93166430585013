import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { Modal } from "semantic-ui-react";

const ContentMeta = (props) => {
  const { page, getContent } = props;
  const [pageContent, setPageContent] = useState(null);
  const [editing, setEditing] = useState(false);
  const [metaEditStatus, setMetaEditStatus] = useState("");
  const [contentMetaModalOpen, setContentMetaModalOpen] = useState(false);

  useEffect(() => {
    document.body.classList.add("meta");
    return () => {
      document.body.classList.remove("meta");
    };
  }, []);

  useEffect(() => {
    db.getPageMeta(page).then((content) => {
      setPageContent(content.val());
    });
  }, [page]);

  const openContentMetaModal = (event) => {
    event.preventDefault();
    setEditing(true);
    setContentMetaModalOpen(true);
  };

  const closeContentMetaModal = () => {
    setContentMetaModalOpen(false);
    setEditing(false);
    setMetaEditStatus("");
  };

  const updateContent = (type, update) => {
    setPageContent({
      ...pageContent,
      [type]: update,
    });
  };

  const saveContent = () => {
    setMetaEditStatus("saving");
    db.savePageMeta(page, pageContent).then(() => {
      setMetaEditStatus("");
      if (getContent) {
        getContent();
      }
      closeContentMetaModal();
    });
  };

  return pageContent ? (
    <div className="content-meta">
      <button
        className="btn btn-primary content-edit btn-small"
        onClick={openContentMetaModal}
      >
        Edit metadata
      </button>
      <Modal
        open={contentMetaModalOpen}
        onClose={closeContentMetaModal}
        closeOnDimmerClick={false}
        className="modal-meta"
      >
        <span className="modal-close" onClick={closeContentMetaModal}>
          <i className="icon icon-cross" />
        </span>
        <Modal.Header>
          <h1>Edit Metadata</h1>
        </Modal.Header>
        <Modal.Content>
          <div className={`content-editor ${editing ? "active" : ""}`}>
            <form
              id="content-meta"
              onSubmit={(event) => {
                event.preventDefault();
                saveContent();
              }}
            >
              <div className={`form-row nocheck`}>
                <label>Meta Title</label>
                <input
                  id="page-title"
                  type="text"
                  value={pageContent.title || ""}
                  placeholder="Enter page title"
                  onChange={(event) =>
                    updateContent("title", event.target.value)
                  }
                />
              </div>
              <div className={`form-row nocheck`}>
                <label>Meta Description</label>
                <textarea
                  id="page-description"
                  type="text"
                  value={pageContent.description || ""}
                  placeholder="Enter page description"
                  onChange={(event) =>
                    updateContent("description", event.target.value)
                  }
                />
              </div>
              <div className={`form-row nocheck`}>
                <label>Schema</label>
                <textarea
                  id="page-schema"
                  type="text"
                  value={pageContent.schema || ""}
                  placeholder="Enter schema code"
                  onChange={(event) =>
                    updateContent("schema", event.target.value)
                  }
                />
              </div>
              <button
                className="btn btn-success content-save"
                onClick={saveContent}
              >
                {metaEditStatus === "saving" ? (
                  <span className="btn-loading" />
                ) : (
                  "save"
                )}
              </button>
            </form>
          </div>
        </Modal.Content>
      </Modal>
    </div>
  ) : null;
};

export default ContentMeta;

import React from "react";
import { connect } from "react-redux";
import { firebase } from "../firebase";

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    componentDidMount() {
      firebase.auth.onAuthStateChanged((authUser) => {
        authUser ? this.setUser(authUser) : this.setUser(false);
      });
    }

    setUser = (authUser) => {
      this.props.setAuthUser(authUser);
    };

    render() {
      return <Component />;
    }
  }

  const mapDispatchToProps = (dispatch) => ({
    setAuthUser: (authUser) => dispatch({ type: "AUTH_USER", authUser }),
  });

  return connect(null, mapDispatchToProps)(WithAuthentication);
};

export default withAuthentication;

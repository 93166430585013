import { storage } from './firebase';
import { ref, getDownloadURL, uploadBytes, uploadBytesResumable, deleteObject } from 'firebase/storage';

export const getDownload = (path) =>
  getDownloadURL(ref(storage, path));

export const getRef = (path) =>
  ref(storage, path);

export const uploadFile = (storageRef, file) =>
  uploadBytes(storageRef, file);

export const uploadFiles = (storageRef, file) =>
  uploadBytesResumable(storageRef, file);

export const deleteFile = (file) =>
  deleteObject(file);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import * as routes from 'constants/routes';

class Index extends Component {

  constructor(props) {
    super(props);
    this.state = ({ redirect: false });
  }

  componentDidMount() {
    const { history } = this.props;
    history.push(routes.Home);
  }

  render() {
    return (
      <div id="index" className="content-outer">
        <div className="content-loading no-header" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser
});

export default compose(
  connect(mapStateToProps)
)(Index);
import React, { Component } from 'react';

export default class PageNotFound extends Component {
  
  componentDidMount() {
    window.scrollTo(0,0);
  }

  render() {
    return (
      <div className="content-intro">
        <div className="page-not-found">
          <h1>404 &mdash; Sorry, we couldn't find this page.</h1>
        </div>
      </div>
    )
  }
}
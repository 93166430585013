import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { HashLink as Link } from "react-router-hash-link";
import { withRouter } from "react-router";
import { Modal } from "semantic-ui-react";
import * as routes from "constants/routes";
import { ContactForm } from "components/core/Contact";
import { onLogOut } from "utils/auth";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  openContactModal = (event) => {
    event.preventDefault();
    this.setState({ contactModalOpen: true });
  };

  closeContactModal = () => {
    this.setState({ contactModalOpen: false });
  };

  render() {
    const { authUser, history } = this.props;

    return (
      <footer id="footer" className="footer-wrap">
        <Modal
          open={this.state.contactModalOpen}
          onClose={this.closeContactModal}
          className="modal-contact-form"
        >
          <span className="modal-close" onClick={this.closeContactModal}>
            <i className="icon icon-cross" />
          </span>
          <Modal.Header>
            <h3>Contact Us</h3>
          </Modal.Header>
          <Modal.Content>
            <ContactForm />
          </Modal.Content>
        </Modal>
        <div className="footer-content">
          <div className="footer-icons">
            <Link to={routes.Home}>
              <img
                src={require("../../images/emblem-white.svg").default}
                alt="Alluvion Communications"
              />
            </Link>
            <div className="social-icons">
              <a
                href="https://twitter.com/alluvioncom"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="icon icon-twitter" />
              </a>
              <a
                href="https://www.facebook.com/alluvioncom/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="icon icon-facebook" />
              </a>
              <a
                href="https://www.linkedin.com/company/alluvioncom/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="icon icon-linkedin" />
              </a>
            </div>
          </div>
          <nav className="nav-footer">
            <ul>
              <li>
                <Link to={routes.Fiber}>fiber</Link>
                <ul>
                  <li>
                    <Link to={`${routes.Fiber}#content-0`}>Dark</Link>
                  </li>
                  <li>
                    <Link to={`${routes.Fiber}#content-1`}>PDWAN</Link>
                  </li>
                  <li>
                    <Link to={`${routes.Fiber}#content-2`}>Wave</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={routes.Data}>data</Link>
                <ul>
                  <li>
                    <Link to={`${routes.Data}#content-0`}>Enterprise</Link>
                  </li>
                  <li>
                    <Link to={routes.BusinessConnect}>Business Connect</Link>
                  </li>
                  <li>
                    <Link to={`${routes.Data}#content-2`}>Connect</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={routes.Voice}>voice</Link>
                <ul>
                  <li>
                    <Link to={`${routes.Voice}#voicecloud`}>VoiceCloud</Link>
                  </li>
                  <li>
                    <Link to={`${routes.Voice}#features`}>Features</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={routes.About}>about</Link>
                <ul>
                  <li>
                    <Link to={`${routes.About}#mission`}>Our Mission</Link>
                  </li>
                  <li>
                    <Link to={`${routes.About}#industry-education`}>
                      Education Solutions
                    </Link>
                  </li>
                  <li>
                    <Link to={`${routes.About}#industry-financial`}>
                      Financial Solutions
                    </Link>
                  </li>
                  <li>
                    <Link to={`${routes.About}#industry-healthcare`}>
                      Healthcare Solutions
                    </Link>
                  </li>
                  <li>
                    <Link to={`${routes.About}#industry-hospitality`}>
                      Hospitality Solutions
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="solo">
                <Link to={routes.Blog}>blog</Link>
              </li>
              <li className="solo">
                <Link to={routes.Contact}>contact</Link>
              </li>
              <li className="solo">
                {/* <a href="https://webapps.paydq.com/newmacc/interceptor" target="_blank" rel="noreferrer noopener">pay bill</a> */}
                <form
                  action="https://ipn4.paymentus.com/cp/grtc"
                  method="POST"
                  target="_blank"
                >
                  <input type="hidden" name="COINTID" value="566" />
                  <input type="hidden" name="GROUPID" value="236" />
                  <input
                    type="hidden"
                    name="URL"
                    value="http://www.gilarivertel.com"
                  />
                      
                  <input type="hidden" name="SSON" value="NO" />
                  <input
                    type="submit"
                    value="pay bill"
                    className="btn btn-text link"
                  />
                </form>
              </li>
              <li className="solo">
                <Link to={routes.Careers}>careers</Link>
              </li>
              <li className="solo">
                <Link to={routes.Resources}>resources</Link>
              </li>
              <li className="solo">
                <Link to={routes.GRIC}>GRIC</Link>
              </li>
              <li className="solo">
                <Link to={routes.AcceptableUse}>acceptable use</Link>
              </li>
              <li className="solo">
                <a
                  href="https://firebasestorage.googleapis.com/v0/b/alluvion-prod.appspot.com/o/docs%2FAlluvion%20Privacy%20Policy%20July%202022_VTKLaw.pdf?alt=media&token=5f93b888-d3ac-4454-97fa-318c76bd8e45"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy
                </a>
              </li>
              {/*<li><Link to={routes.Styleguide} target="_blank">styleguide</Link></li>*/}
              {authUser ? (
                <li className="solo">
                  <Link to={routes.Admin}>admin</Link>
                </li>
              ) : null}
              {authUser ? (
                <li className="solo" onClick={() => onLogOut(history)}>
                  <span className="link">logout</span>
                </li>
              ) : null}
            </ul>

            {/*<p>&copy; {new Date().getFullYear()} &ndash; Alluvion Communications</p>*/}
          </nav>
        </div>
        <button
          id="get-in-touch"
          className="btn btn-primary"
          onClick={(event) => this.openContactModal(event)}
        >
          get in touch
        </button>
      </footer>
    );
  }
}

const INITIAL_STATE = {
  contactModalOpen: false,
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default compose(withRouter, connect(mapStateToProps))(Footer);

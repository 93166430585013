import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";

// DEV
const configDev = {
  apiKey: "AIzaSyAABzePLY2cMk9VDsKvulF4mGEAcXRZQfw",
  authDomain: "alluvion-dev.firebaseapp.com",
  databaseURL: "https://alluvion-dev.firebaseio.com",
  projectId: "alluvion-dev",
  storageBucket: "alluvion-dev.appspot.com",
  messagingSenderId: "969053686595",
};

// PROD
const configProd = {
  apiKey: "AIzaSyD3fVF3dDuZqKnIS7jLd8n6___dTmVVKzU",
  authDomain: "alluvion-prod.firebaseapp.com",
  databaseURL: "https://alluvion-prod.firebaseio.com",
  projectId: "alluvion-prod",
  storageBucket: "alluvion-prod.appspot.com",
  messagingSenderId: "31092392715",
};

const appEnv = process.env.REACT_APP_ENV;
const config = appEnv === "production" ? configProd : configDev;

export const app = initializeApp(config);
export const adminApp = initializeApp(config, "secondary");
export const db = getDatabase(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
export const sendContactEmail = httpsCallable(functions, "sendContactEmail");
export const sendBandwidthCalc = httpsCallable(functions, "sendBandwidthCalc");
export const sendJobApplication = httpsCallable(
  functions,
  "sendJobApplication"
);
export const sendVoIPEmail = httpsCallable(functions, "sendVoIPEmail");
export const removeUserAuthentication = httpsCallable(
  functions,
  "removeUserAuthentication"
);

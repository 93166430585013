import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import { db, storage } from "../../firebase";
import { Confirm, Menu, Dropdown } from "semantic-ui-react";
import pretty from "pretty";
import arrayMove from "array-move";
import {
  modulesHeader,
  modulesContent,
  modulesContentSlim,
  formatsHeader,
  formatsContent,
  formatsContentSlim,
} from "../Functions";

const ContentEditor = (props) => {
  const { page, pageContent, item, itemIndex, type, solo, getContent } = props;
  const [content, setContent] = useState([]);
  const [contentID, setContentID] = useState("");
  const [editing, setEditing] = useState(false);
  const [contentEditStatus, setContentEditStatus] = useState("");
  const [postDeleteConfirmOpen, setPostDeleteConfirmOpen] = useState(false);

  useEffect(() => {
    setContent(
      pageContent && pageContent[item] && pageContent[item][itemIndex]
        ? pageContent[item][itemIndex][type]
        : pageContent[item][type]
        ? pageContent[item][type]
        : []
    );
    setContentID(
      pageContent && pageContent[item] && pageContent[item][itemIndex]
        ? pageContent[item][itemIndex].id
        : pageContent[item][type]
        ? pageContent[item].id
        : []
    );
  }, [pageContent, item, itemIndex, type]);

  const saveContent = () => {
    setContentEditStatus("saving");
    db.getPageContent(page).then((siteContent) => {
      const contentUpdate = siteContent.val();
      if (contentID) {
        db.getPostIndex(page, item, contentID).then((res) => {
          const postIndex = Object.keys(res.val())[0];
          contentUpdate[item][postIndex][type] = content;
          updateDB(`${item}/${postIndex}`, content);
        });
      } else if (!contentID && itemIndex) {
        contentUpdate[item][itemIndex][type] = content;
        updateDB(`${item}/${itemIndex}`, content);
      } else {
        contentUpdate[item][type] = content;
        updateDB(item, content);
      }
    });
  };

  const updateDB = (path, content, update) => {
    db.saveContent(page, path, { [type]: pretty(content) }).then(() => {
      if (getContent) {
        getContent();
      }
      setContentEditStatus("");
    });
  };

  const deletePost = () => {
    setContentEditStatus("deleting");
    setPostDeleteConfirmOpen(false);
    setTimeout(() => {
      db.getPageContent(page).then((siteContent) => {
        const contentUpdate = siteContent.val();
        db.getPostIndex(page, "posts", contentID).then((res) => {
          const postIndex = Object.keys(res.val())[0];
          const imageRef = contentUpdate.posts[postIndex].img;
          contentUpdate.posts.splice(postIndex, 1);
          db.savePosts(page, contentUpdate.posts).then(() => {
            if (getContent) {
              getContent();
            }
            const imageFile = storage.getRef(imageRef);
            if (imageRef && imageFile) {
              storage.deleteFile(imageFile);
            }
            setContentEditStatus("");
          });
        });
      });
    }, 500);
  };

  const movePost = (postID, dir) => {
    db.getPageContent(page).then((siteContent) => {
      const contentUpdate = siteContent.val();
      db.getPostIndex(page, "posts", contentID).then((res) => {
        const postIndex = Object.keys(res.val())[0];
        if (dir === "up") {
          contentUpdate.posts = arrayMove(
            contentUpdate.posts,
            postIndex,
            postIndex - 1
          );
        } else if (dir === "down") {
          contentUpdate.posts = arrayMove(
            contentUpdate.posts,
            postIndex,
            postIndex + 1
          );
        } else if (dir === "top") {
          contentUpdate.posts = arrayMove(contentUpdate.posts, postIndex, 0);
        } else if (dir === "bottom") {
          contentUpdate.posts = arrayMove(
            contentUpdate.posts,
            postIndex,
            contentUpdate.posts.length - 1
          );
        }
        db.savePosts(page, contentUpdate.posts).then(() => {
          if (getContent) {
            getContent();
          }
        });
      });
    });
  };

  const updateContent = (content, source) => {
    if (source === "user") {
      setContent(content);
    }
  };

  const editOn = () => {
    if (!editing) {
      setEditing(true);
    }
  };

  const editOff = () => {
    if (editing) {
      setEditing(false);
    }
  };

  return (
    <div
      className={`content-editor-wrap content-text ${
        contentEditStatus === "deleting" ? "deleting" : ""
      }`}
    >
      {content && content.length > 0 ? (
        <span
          className={`content-editor ${editing ? "active" : ""}`}
          onClick={editOn}
        >
          <span className="content-display">
            <span dangerouslySetInnerHTML={{ __html: content }} />
          </span>
          <ReactQuill
            theme="snow"
            className="content-edit"
            value={content}
            modules={
              item === "header"
                ? modulesHeader
                : item === "posts"
                ? modulesContent
                : modulesContentSlim
            }
            formats={
              item === "header"
                ? formatsHeader
                : item === "posts"
                ? formatsContent
                : formatsContentSlim
            }
            onChange={(content, delta, source, editor) =>
              updateContent(content, source)
            }
          />
          <button
            className="btn btn-success content-save"
            onClick={saveContent}
            disabled={contentEditStatus !== ""}
          >
            {contentEditStatus === "saving" ? (
              <span className="btn-loading" />
            ) : (
              "save"
            )}
          </button>
          <button
            className="btn btn-white content-cancel"
            onClick={editOff}
            title="Cancel edit"
          >
            <i className="icon icon-cross" />
          </button>
        </span>
      ) : null}

      {item.indexOf("post") !== -1 && !solo ? (
        <span className="content-post-nav">
          <Menu className="dark">
            <Dropdown
              trigger={
                <div className="trigger">
                  <i className="icon icon-menu" />
                </div>
              }
            >
              <Dropdown.Menu>
                {itemIndex > 0 ? (
                  <span
                    className="link menu-item"
                    onClick={() => movePost(itemIndex, "top")}
                  >
                    <i className="icon icon-enter-up" /> move top
                  </span>
                ) : null}
                {itemIndex > 0 ? (
                  <span
                    className="link menu-item"
                    onClick={() => movePost(itemIndex, "up")}
                  >
                    <i className="icon icon-arrow-up" /> move up
                  </span>
                ) : null}
                {itemIndex < pageContent.posts.length - 1 ? (
                  <span
                    className="link menu-item"
                    onClick={() => movePost(itemIndex, "down")}
                  >
                    <i className="icon icon-arrow-down" /> move down
                  </span>
                ) : null}
                {itemIndex < pageContent.posts.length - 1 ? (
                  <span
                    className="link menu-item"
                    onClick={() => movePost(itemIndex, "bottom")}
                  >
                    <i className="icon icon-enter-down" /> move bottom
                  </span>
                ) : null}
                <span
                  className="link menu-item"
                  onClick={() => setPostDeleteConfirmOpen(true)}
                >
                  <i className="icon icon-trash" /> delete
                </span>
              </Dropdown.Menu>
            </Dropdown>
          </Menu>
          <Confirm
            className="confirm delete"
            open={postDeleteConfirmOpen}
            header="Sure you want to delete this post?"
            content="The post will be permanently removed."
            cancelButton="nevermind"
            confirmButton="yes, delete"
            onCancel={() => setPostDeleteConfirmOpen(false)}
            onConfirm={deletePost}
          />
        </span>
      ) : null}
      <span className="content-loading" />
    </div>
  );
};

export default ContentEditor;

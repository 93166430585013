import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import * as routes from "constants/routes";
import Navigation from "components/core/Navigation";

const Header = () => {
  const [headerShow, setHeaderShow] = useState(false);
  const [headerHide, setHeaderHide] = useState(false);
  const oldScroll = useRef(0);

  const handleScroll = useCallback(
    (event) => {
      let pageY = window.scrollY;
      if (
        pageY > 500 &&
        headerShow &&
        oldScroll.current < pageY &&
        !headerHide
      ) {
        setHeaderHide(true);
      } else if (
        pageY > 500 &&
        !headerShow &&
        oldScroll.current > pageY &&
        headerHide
      ) {
        setHeaderHide(false);
      }
      if (pageY > 1000 && !headerShow && oldScroll.current > pageY) {
        setHeaderShow(true);
      } else if ((pageY < 500 && headerShow) || oldScroll.current < pageY) {
        setHeaderShow(false);
      }
      if (pageY === 0) {
        setHeaderShow(false);
        setHeaderHide(false);
      }
      oldScroll.current = pageY;
    },
    [headerHide, headerShow]
  );

  useEffect(() => {
    if (window.innerWidth > 1024) {
      window.addEventListener("scroll", handleScroll);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <header
      id="header"
      className={`header-wrap ${headerShow ? "show" : ""} ${
        headerHide ? "hide" : ""
      }`}
    >
      <div className="logo-wrap">
        <Link className="logo-link" to={routes.Home}>
          <div className="logo-img" />
        </Link>
      </div>
      <Navigation />
    </header>
  );
};

export default Header;

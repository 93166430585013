import React, { Component } from "react";
import { connect } from "react-redux";
import { HashLink as Link } from "react-router-hash-link";
import { Menu, Dropdown } from "semantic-ui-react";
import * as routes from "constants/routes";

class Navigation extends Component {
  render() {
    return (
      <nav className="nav-main">
        {window.innerWidth <= 1024 ? (
          <span className="nav-mobile">
            <Menu>
              <Dropdown
                trigger={
                  <div className="nav-main-trigger">
                    <i className="icon icon-menu" />
                  </div>
                }
              >
                <ul>
                  <li className="nav-home">
                    <Link to={routes.Home}>home</Link>
                  </li>
                  <li className="nav-fiber">
                    <Link to={routes.Fiber}>fiber</Link>
                  </li>
                  <li className="nav-data">
                    <Link to={routes.Data}>data</Link>
                  </li>
                  <li className="nav-voice">
                    <Link to={routes.Voice}>voice</Link>
                  </li>
                  <li className="nav-about">
                    <Link to={routes.About}>about</Link>
                  </li>
                  <li className="nav-blog">
                    <Link to={routes.Blog}>blog</Link>
                  </li>
                  <li className="nav-contact">
                    <Link to={routes.Contact}>contact</Link>
                  </li>
                  <li className="nav-pay">
                    <form
                      action="https://ipn4.paymentus.com/cp/grtc"
                      method="POST"
                      target="_blank"
                    >
                      <input type="hidden" name="COINTID" value="566" />
                      <input type="hidden" name="GROUPID" value="236" />
                      <input
                        type="hidden"
                        name="URL"
                        value="http://www.gilarivertel.com"
                      />
                          
                      <input type="hidden" name="SSON" value="NO" />
                      <input
                        type="submit"
                        value="pay bill"
                        className="btn btn-accent btn-small"
                      />
                    </form>
                  </li>
                </ul>
              </Dropdown>
            </Menu>
          </span>
        ) : (
          <ul>
            <li className="nav-home">
              <Link to={routes.Home}>home</Link>
            </li>
            <li className="nav-fiber">
              <Link to={routes.Fiber}>fiber</Link>
              <Menu>
                <Dropdown
                  trigger={
                    <div className="nav-main-trigger">
                      <i className="icon icon-chevron-down" />
                    </div>
                  }
                >
                  <div className="nav-main-dropdown">
                    <Link to={`${routes.Fiber}#content-0`}>Dark</Link>
                    <Link to={`${routes.Fiber}#content-1`}>PDWAN</Link>
                    <Link to={`${routes.Fiber}#content-2`}>Wave</Link>
                  </div>
                </Dropdown>
              </Menu>
            </li>
            <li className="nav-data">
              <Link to={routes.Data}>Data</Link>
              <Menu>
                <Dropdown
                  trigger={
                    <div className="nav-main-trigger">
                      <i className="icon icon-chevron-down" />
                    </div>
                  }
                >
                  <div className="nav-main-dropdown">
                    <Link to={`${routes.Data}#content-0`}>Enterprise</Link>
                    <Link to={routes.BusinessConnect}>Business Connect</Link>
                    <Link to={`${routes.Data}#content-2`}>Connect</Link>
                  </div>
                </Dropdown>
              </Menu>
            </li>
            <li className="nav-voice">
              <Link to={routes.Voice}>voice</Link>
              <Menu>
                <Dropdown
                  trigger={
                    <div className="nav-main-trigger">
                      <i className="icon icon-chevron-down" />
                    </div>
                  }
                >
                  <div className="nav-main-dropdown">
                    <Link to={`${routes.Voice}#voicecloud`}>VoiceCloud</Link>
                    <Link to={`${routes.Voice}#features`}>Features</Link>
                  </div>
                </Dropdown>
              </Menu>
            </li>
            <li className="nav-about">
              <Link to={routes.About}>about</Link>
            </li>
            <li className="nav-blog">
              <Link to={routes.Blog}>blog</Link>
            </li>
            <li className="nav-contact">
              <Link to={routes.Contact}>contact</Link>
            </li>
            <li className="nav-pay">
              {/* <a className="btn btn-accent btn-small" href="https://webapps.paydq.com/newmacc/interceptor" target="_blank" rel="noreferrer noopener">pay bill</a> */}
              <form
                action="https://ipn4.paymentus.com/cp/grtc"
                method="POST"
                target="_blank"
              >
                <input type="hidden" name="COINTID" value="566" />
                <input type="hidden" name="GROUPID" value="236" />
                <input
                  type="hidden"
                  name="URL"
                  value="http://www.gilarivertel.com"
                />
                    
                <input type="hidden" name="SSON" value="NO" />
                <input
                  type="submit"
                  value="pay bill"
                  className="btn btn-accent btn-small"
                />
              </form>
            </li>
          </ul>
        )}
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default connect(mapStateToProps)(Navigation);

import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Content from "../content/Content";
import ContentAdd from "../content/ContentAdd";
import { db } from "../../firebase";

export const Testimonials = (props) => {
  const authUser = useSelector((state) => state.sessionState.authUser);
  const [slide, setSlide] = useState(0);
  const [pageContent, setPageContent] = useState(null);
  const timeout = useRef(null);

  const getContent = () => {
    db.getPageContent("testimonials").then((content) => {
      setPageContent(content.val());
    });
  };

  useEffect(() => {
    getContent();
    const sections = [...document.getElementsByClassName("testimonial-item")];
    const options = {
      rootMargin: "0px",
      threshold: 0.25,
    };
    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio >= 0.25) {
          entry.target.classList.add("visible");
          clearTimeout(timeout.current);
          timeout.current = setTimeout(
            () => setSlide(Number(entry.target.id.replace("item-", ""))),
            250
          );
        } else {
          entry.target.classList.remove("visible");
        }
      });
    };
    const observer = new IntersectionObserver(callback, options);
    sections.forEach((section) => {
      observer.observe(section);
    });
  }, []);

  const changeSlide = (index) => {
    setSlide(index);
    const testList = document.getElementById("testimonials-list");
    if (index < slide) {
      testList.scrollLeft -= testList.offsetWidth * (slide - index);
    } else if (index > slide) {
      testList.scrollLeft += testList.offsetWidth * (index - slide);
    }
  };

  const navPrev = () => {
    if (slide === 0) changeSlide(pageContent.posts.length - 1);
    else changeSlide(slide - 1);
  };

  const navNext = () => {
    if (slide === pageContent.posts.length - 1) changeSlide(0);
    else changeSlide(slide + 1);
  };

  return pageContent ? (
    <div className="testimonials testimonials-outer" id="testimonials">
      <h2>What our customers have to say...</h2>
      <div className="testimonials-wrap">
        <div
          id="testimonials-list"
          className={`testimonials-items slide-${slide}`}
        >
          {pageContent.posts ? (
            pageContent.posts.map((post, index) => (
              <div
                id={`item-${index}`}
                className="testimonial-item"
                tabIndex={index}
                key={index}
              >
                <div className="item-snap" />
                <Content
                  page="testimonials"
                  pageContent={pageContent}
                  item="posts"
                  itemIndex={index}
                  type="content"
                />
              </div>
            ))
          ) : (
            <p className="error">No testimonials</p>
          )}
        </div>
        <div className="testimonial-dots">
          {pageContent.posts
            ? pageContent.posts.map((post, index) => (
                <div
                  key={index}
                  className={`testimonial-dot ${
                    slide === index ? "active" : ""
                  }`}
                  onClick={() => changeSlide(index)}
                />
              ))
            : null}
        </div>
      </div>
      <div className="testimonial-nav">
        <div className="nav-prev" onClick={navPrev}>
          <i className="icon icon-arrow-left" />
        </div>
        <div className="nav-next" onClick={navNext}>
          <i className="icon icon-arrow-right" />
        </div>
      </div>
      {authUser && (
        <ContentAdd
          page="testimonials"
          item="posts"
          type="content"
          image="false"
          getContent={getContent}
        />
      )}
    </div>
  ) : null;
};

export default Testimonials;

import { db } from "./firebase";
import {
  ref,
  get,
  query,
  set,
  remove,
  update,
  orderByChild,
  equalTo,
} from "firebase/database";

// User Functions

export const createUser = (id, email, role) =>
  set(ref(db, `users/${id}`), {
    email: email,
    lowercase_email: email.toLowerCase(),
    role: role,
  });

export const getUser = (userID) => get(ref(db, `users/${userID}`));

export const getUsers = () => get(ref(db, "users/"));

export const getInvites = () => get(ref(db, "invites/"));

export const lookupUserByEmail = (email) =>
  get(
    query(ref(db, "users/"), orderByChild("lowercase_email"), equalTo(email))
  );

// Content Functions

export const getPageContent = (page, id) =>
  get(ref(db, `content/${page}${id ? `/posts/${id}` : ""}`));

export const saveContent = (page, item, content) =>
  update(ref(db, `content/${page}/${item}`), content);

export const getPageMeta = (page) => get(ref(db, `content/${page}/meta`));

export const savePageMeta = (page, content) =>
  update(ref(db, `content/${page}/meta`), content);

export const saveCategories = (categories) =>
  set(ref(db, `content/blog/categories`), categories);

export const saveMapContent = (content) =>
  update(ref(db, `content/map/content`), content);

export const getPostIndex = (page, item, id) =>
  get(
    query(ref(db, `content/${page}/${item}`), orderByChild("id"), equalTo(id))
  );

export const saveCareerPost = (post) =>
  get(
    query(
      ref(db, "content/careers/posts"),
      orderByChild("id"),
      equalTo(post.id)
    )
  ).then((snapshot) => {
    if (snapshot.val()) {
      return update(
        ref(db, `content/careers/posts/${Object.keys(snapshot.val())[0]}`),
        post
      );
    } else {
      return get(ref(db, "content/careers/posts")).then((snapshot) => {
        return update(
          ref(
            db,
            `content/careers/posts/${
              snapshot.val() ? snapshot.val().length : 0
            }`
          ),
          post
        );
      });
    }
  });

export const getBlogCategories = () =>
  get(query(ref(db, "content/blog/categories")));

export const getRelatedPosts = (id, category, count) =>
  get(query(ref(db, `content/blog/posts`))).then((snapshot) => {
    if (snapshot.val()) {
      let related = [];
      let matched = 0;
      snapshot.val().map((post) => {
        const hasMatch =
          post.id !== id && post.category
            ? category.filter((e) => post.category.includes(e))
            : -1;
        if (hasMatch !== -1 && matched < count) {
          matched++;
          return related.push(post);
        } else {
          return null;
        }
      });
      return related;
    } else {
      return [];
    }
  });

export const getBlogPost = (id, type) =>
  get(query(ref(db, `content/blog/posts`), orderByChild(type), equalTo(id)));

export const saveBlogPost = (post) => {
  return get(
    query(ref(db, "content/blog/posts"), orderByChild("id"), equalTo(post.id))
  ).then((snapshot) => {
    if (snapshot.val()) {
      return update(
        ref(db, `content/blog/posts/${Object.keys(snapshot.val())[0]}`),
        post
      );
    } else {
      return get(ref(db, "content/blog/posts")).then((snapshot) => {
        return update(
          ref(
            db,
            `content/blog/posts/${snapshot.val() ? snapshot.val().length : 0}`
          ),
          post
        );
      });
    }
  });
};

export const deleteBlogPost = (id) =>
  get(
    query(ref(db, "content/blog/posts"), orderByChild("id"), equalTo(id))
  ).then((snapshot) => {
    if (snapshot.val()) {
      return remove(
        ref(db, `content/blog/posts/${Object.keys(snapshot.val())[0]}`)
      );
    } else {
      return false;
    }
  });

export const savePost = (page, id, post) =>
  set(ref(db, `content/${page}/posts/${id}`), post);

export const savePosts = (page, posts) =>
  set(ref(db, `content/${page}/posts`), posts);

export const getSettings = async () => {
  try {
    const emailPromise = get(ref(db, "settings"));
    const blogPromise = get(ref(db, "content/blog/categories"));
    const resourcesPromise = get(ref(db, "content/resources/files"));
    const gricPromise = get(ref(db, "content/gric/files"));

    const [emailSnapshot, blogSnapshot, resourcesSnapshot, gricSnapshot] =
      await Promise.all([
        emailPromise,
        blogPromise,
        resourcesPromise,
        gricPromise,
      ]);

    const email = emailSnapshot.val();
    const blog = blogSnapshot.val();
    const resources = resourcesSnapshot.val();
    const gric = gricSnapshot.val();

    return { email, blog, resources, gric };
  } catch (error) {
    console.error("Error fetching settings:", error);
    throw error;
  }
};

export const updateEmailSettings = (careers, contact, leadGen) =>
  set(ref(db, "settings"), {
    emailCareers: careers,
    emailContact: contact,
    emailLeadGen: leadGen,
  });

export const inviteNewUser = (id, email, callback) => {
  set(ref(db, `invites/${id}`), { email: email }).then(() => callback());
};

export const removeNewUserFromItemInviteTable = (userKey) =>
  set(ref(db, `invites/${userKey}`), []);

export const deleteCategory = (catKey) =>
  remove(ref(db, `content/blog/categories/${catKey}`));

export const saveFiles = (files, type) =>
  set(ref(db, `content/${type}/files`), files);

import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import { db, storage } from "../../firebase";
import { Modal } from "semantic-ui-react";
import pretty from "pretty";
import shortid from "shortid";
import Dropzone from "react-dropzone";
import {
  convertFileSize,
  modulesHeader,
  modulesContent,
  formatsHeader,
  formatsContent,
} from "../Functions";

const ContentAdd = (props) => {
  const { page, item, type, image, getContent } = props;
  const [contentAddStatus, setContentAddStatus] = useState("");
  const [contentAddHeader, setContentAddHeader] = useState("<h2>\n</h2>");
  const [contentAddContent, setContentAddContent] = useState("");
  const [contentAddImg, setContentAddImg] = useState([]);
  const [contentAddImgUpload, setContentAddImgUpload] = useState([]);
  const [contentAddID, setContentAddID] = useState("");
  const [error, setError] = useState([]);
  const [contentAddModalOpen, setContentAddModalOpen] = useState(false);

  useEffect(() => {
    return () => {
      contentAddImgUpload.forEach((upload) => {
        upload.cancel();
      });
    };
  }, [contentAddImgUpload]);

  const openContentAddModal = (event) => {
    event.preventDefault();
    setContentAddModalOpen(true);
    setContentAddID(shortid.generate());
  };

  const closeContentAddModal = () => {
    setContentAddStatus("");
    setContentAddHeader("<h2>\n</h2>");
    setContentAddContent("");
    setContentAddImg([]);
    setContentAddImgUpload([]);
    setContentAddID("");
    setError([]);
    setContentAddModalOpen(false);
  };

  const updateQuill = (item, content, delta, source, editor) => {
    if (source === "user") {
      if (item === "contentAddContent") {
        setContentAddContent(editor.getLength() > 1 ? content : "");
      } else if (item === "contentAddHeader") {
        setContentAddHeader(editor.getLength() > 1 ? content : "<h2>\n</h2>");
      }
    }
  };

  const focusQuill = (item) => {
    document.getElementById(`quill-${item}`).classList.add("focus");
  };

  const blurQuill = (item) => {
    document.getElementById(`quill-${item}`).classList.remove("focus");
  };

  const setContentImgFile = (file) => {
    setContentAddImg(file);
  };

  const removeContentImgFile = (event) => {
    event.preventDefault();
    setContentAddImg([]);
  };

  const saveContentAdd = (event) => {
    event.preventDefault();
    setContentAddStatus("posting");
    db.getPageContent(page).then((siteContent) => {
      const contentUpdate = siteContent.val();
      const contentIndex = contentUpdate[item] ? contentUpdate[item].length : 0;

      if (image !== "false") {
        const storageRef = storage.getRef(
          `content/${page}/${item}/${contentAddID}/${contentAddImg[0].name}`
        );
        const uploadTask = storage.uploadFiles(storageRef, contentAddImg[0]);
        contentAddImgUpload.push(uploadTask);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            if (snapshot.bytesTransferred === snapshot.totalBytes) {
              console.log("Save Complete");
            }
          },
          (error) => {
            console.log("Upload error: ", error);
            setError(error);
          },
          () => {
            if (!contentUpdate[item]) {
              contentUpdate[item] = [];
            }
            storage.getDownload(uploadTask.snapshot.ref).then((downloadURL) => {
              saveContentDone(contentIndex, contentUpdate, downloadURL);
            });
          }
        );
      } else {
        saveContentDone(contentIndex, contentUpdate);
      }
    });
  };

  const saveContentDone = (contentIndex, contentUpdate, downloadURL) => {
    contentUpdate[page][item][contentIndex] = {
      id: contentAddID,
      header: type === "header" ? contentAddHeader : [],
      content: contentAddContent,
      img: downloadURL ? downloadURL : [],
    };
    db.saveContent(page, `${item}/${contentIndex}`, {
      id: contentAddID,
      header: type === "header" ? pretty(contentAddHeader) : [],
      content: pretty(contentAddContent),
      img: downloadURL ? downloadURL : [],
    }).then(() => {
      setTimeout(() => {
        if (getContent) {
          getContent();
        }
        setContentAddStatus("posted");
        setTimeout(() => {
          closeContentAddModal();
        }, 1000);
      });
    }, 750);
  };

  const isInvalid =
    (type === "header" && contentAddHeader === "") ||
    contentAddContent === "" ||
    (!contentAddImg[0] && image !== "false");

  return (
    <div className="content-new">
      <button className="btn btn-quaternary" onClick={openContentAddModal}>
        new section
      </button>
      <Modal
        open={contentAddModalOpen}
        onClose={closeContentAddModal}
        closeOnDimmerClick={false}
        className="modal-add-section"
      >
        <span className="modal-close" onClick={closeContentAddModal}>
          <i className="icon icon-cross" />
        </span>
        <Modal.Header>
          <h1>Add New Section</h1>
        </Modal.Header>
        <Modal.Content>
          <div className="content-add">
            <form id="content-add-form" onSubmit={saveContentAdd}>
              {type === "header" ? (
                <div
                  className={`form-row row-content content-header ${
                    contentAddHeader && contentAddHeader.length > 0
                      ? "complete"
                      : ""
                  }`}
                >
                  <label className="required">Section Header</label>
                  <ReactQuill
                    id="quill-content"
                    value={contentAddHeader || ""}
                    modules={modulesHeader}
                    formats={formatsHeader}
                    placeholder="Enter section content"
                    onFocus={() => focusQuill("content")}
                    onBlur={() => blurQuill("content")}
                    onChange={(content, delta, source, editor) =>
                      updateQuill(
                        "contentAddHeader",
                        content,
                        delta,
                        source,
                        editor
                      )
                    }
                  />
                </div>
              ) : null}
              <div
                className={`form-row row-content ${
                  contentAddContent && contentAddContent.length > 1
                    ? "complete"
                    : ""
                }`}
              >
                <label className="required">Section Content</label>
                <ReactQuill
                  id="quill-content"
                  theme="snow"
                  value={contentAddContent || ""}
                  modules={modulesContent}
                  formats={formatsContent}
                  placeholder="Enter section content"
                  onFocus={() => focusQuill("content")}
                  onBlur={() => blurQuill("content")}
                  onChange={(content, delta, source, editor) =>
                    updateQuill(
                      "contentAddContent",
                      content,
                      delta,
                      source,
                      editor
                    )
                  }
                />
              </div>
              {image !== "false" ? (
                <div
                  className={`form-row row-img ${
                    contentAddImg && contentAddImg[0] ? "complete" : ""
                  }`}
                >
                  <label className="required">Section Image</label>
                  {contentAddImg && contentAddImg[0] ? (
                    <span className="file-content file-img">
                      <img
                        src={contentAddImg[0].preview}
                        alt={contentAddImg[0].name}
                      />
                      <p>
                        {contentAddImg[0].name} &ndash;{" "}
                        <span>{convertFileSize(contentAddImg[0].size)}</span>
                      </p>
                      <button
                        className="btn btn-text"
                        onClick={removeContentImgFile}
                      >
                        remove file
                      </button>
                    </span>
                  ) : (
                    <Dropzone
                      style={{}}
                      multiple={false}
                      disabled={contentAddStatus !== ""}
                      className="content-add-img-dropzone files-dropzone"
                      activeClassName="active"
                      onDrop={(file) => setContentImgFile(file)}
                    >
                      <div className="content-img-drop files-drop">
                        <h4>Drop the section image here or...</h4>
                        <div className="btn btn-primary">click to upload</div>
                        <span className="files-drop-msg">
                          <i className="icon icon-dropzone" />
                          <p>Drop file here</p>
                        </span>
                      </div>
                    </Dropzone>
                  )}
                </div>
              ) : null}
              <button
                className="btn btn-quaternary"
                type="submit"
                disabled={isInvalid || contentAddStatus !== ""}
              >
                {contentAddStatus === "posting" ? (
                  <span className="btn-loading" />
                ) : contentAddStatus === "posted" ? (
                  <span className="btn-complete">
                    <i className="icon icon-check" />
                    section added!
                  </span>
                ) : (
                  "add section"
                )}
              </button>
              {error && <p className="error">{error.message}</p>}
            </form>
          </div>
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default ContentAdd;

import { auth } from "./firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updatePassword,
} from "firebase/auth";

// Sign Up
export const doCreateUserWithEmailAndPassword = (email, password) =>
  createUserWithEmailAndPassword(auth, email, password);

// Sign In
export const doSignInWithEmailAndPassword = (email, password) =>
  signInWithEmailAndPassword(auth, email, password);

// Password Reset Email
export const doPasswordReset = (email) => sendPasswordResetEmail(auth, email);

// Password Change
export const doPasswordUpdate = (password) =>
  updatePassword(auth.currentUser, password);

// Sign out
export const doSignOut = () => signOut(auth);
